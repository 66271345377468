.flip-card {
    background-color: transparent;
    perspective: 1000px;
    color: white;
    padding-bottom: 20px;
  }
  @media screen and (min-width:500px) {
    .flip-card {
      background-color: transparent;
      width: 310px;
      height: 204px;
      perspective: 1000px;
      color: white;
    }
   
  }
  @media screen and (max-width:500px) {
    .flip-card {
      scale: 0.8;
    }
    .flip-card-flipped{
      scale: 0.8;
    }
  }
  
  .heading_8264 {
    position: absolute;
    letter-spacing: .2em;
    font-size: 0.5em;
    top: 2em;
    left: 18.6em;
  }
  
  .logoCards {
    position: absolute;
    top: 6.8em;
    left: 11.7em;
  }
  
  .chipCards {
    position: absolute;
    top: 2.3em;
    left: 1.5em;
  }
  
  .contactless {
    position: absolute;
    top: 3.5em;
    left: 12.4em;
  }
  
  .numberCards {
    position: absolute;
    font-weight: bold;
    font-size: .6em;
    top: 8.3em;
    left: 1.6em;
  }
  
  .valid_thru {
    position: absolute;
    font-weight: bold;
    top: 635.8em;
    font-size: .01em;
    left: 140.3em;
  }
  
  .date_8264 {
    position: absolute;
    font-weight: bold;
    font-size: 0.5em;
    top: 13.6em;
    left: 3.2em;
  }
  
  .nameCards {
    position: absolute;
    font-weight: bold;
    font-size: 0.5em;
    top: 16.1em;
    left: 2em;
  }
  
  .strip {
    position: absolute;
    background-color: black;
    width: 15em;
    height: 1.5em;
    top: 2.4em;
    background: repeating-linear-gradient(
      45deg,
      #303030,
      #303030 10px,
      #202020 10px,
      #202020 20px
    );
  }
  
  .mstrip {
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 8em;
    height: 0.8em;
    top: 5em;
    left: .8em;
    border-radius: 2.5px;
  }
  
  .sstrip {
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 4.1em;
    height: 0.8em;
    top: 5em;
    left: 10em;
    border-radius: 2.5px;
  }
  
  .codeCards {
    font-weight: bold;
    text-align: center;
    margin: .2em;
    color: black;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    /* box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2); */
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    /* border-radius: 1rem; */
  }
  
  .flip-card-front {
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -1px 0px inset; */
    /* background-color: #171717; */
  }
  
  .flip-card-back {
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -1px 0px inset; */
    /* background-color: #171717; */
    transform: rotateY(180deg);
  }

  .image_slider_pic{
    width: 400px;
    height: 400px;
    margin-right: 150px;
  }


  @media screen and (max-width:990px) {
    .image_slider_pic{
      width: 200px;
      height: 180px;
      margin-right: 150px;
    }
  }
  