.custom-loader-modal .modal-content {
    background-color: rgba(0, 0, 0, 0) !important; /* Adjust the alpha value to control transparency */
  }

.CustomLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: not-allowed;
    scale: 0.7;
  }
  
  .central {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 25em;
    height: 25em;
    border-radius: 50%;
    box-shadow: 0.5em 1em 1em blue,
      -0.5em 0.5em 1em lime,
      0.5em -0.5em 1em cyan,
      -0.5em -0.5em 1em yellow;
  }
  
  .external-shadow {
    width: 20em;
    height: 20em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0.5em 0.5em 3em blue,
      -0.5em 0.5em 3em lime,
      0.5em -0.5em 3em cyan,
      -0.5em -0.5em 3em yellow;
    z-index: 999;
    animation: rotate 3s linear infinite;
    background-color: #212121;
  }
  
  .intern {
    position: absolute;
    color: white;
    z-index: 9999;
  }
  
  .intern::before {
    content: "100%";
    font-size: 40px;
    animation: percent 1s ease-in-out infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
  
    50% {
      transform: rotate(180deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes percent {
    0% {
      content: 'Processing...';
    }
  
    25% {
      content: '25%';
    }
  
    33% {
      content: '33%';
    }
  
    42% {
      content: '42%';
    }
  
    51% {
      content: '51%';
    }
  
    67% {
      content: '67%';
    }
  
    74% {
      content: '74%';
    }
  
    75% {
      content: '75%';
    }
  
    86% {
      content: '86%';
    }
  
    95% {
      content: '95%';
    }
  
    98% {
      content: '98%';
    }
  
    99% {
      content: '99%';
    }
  }