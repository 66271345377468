.pay-modal .modal-content {
  background-color: rgba(0, 0, 0, 0.2);
  /* Adjust the alpha value to control transparency */
}

.flip-card {
  width: 500px;
  height: 300px;
  position: relative;
  perspective: 1000px;
  margin: 20px 0px 20px 0px;
  border-radius: 20px;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  /* Maintain 3D effect */
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
  /* Flip when the 'flipped' class is added */
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  /* Hide the back during the flip */
}

.flip-card-front {
  /* background-color: #bbb; */
}

.flip-card-back {
  /* background-color: #2980b9; */
  transform: rotateY(180deg);
}

.flip-card-front-name {
  position: absolute;
  bottom: 0px;
  left: 32px;
  color: white;
  z-index: 1000;
  /* writing-mode: vertical-rl; */
  font-size: 20px;
  font-weight: 400;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 3px;

}

.flip-card-back-name {
  position: absolute;
  right: 78px;
  top: 105px;
  /* bottom: 23px; */
  /* left: 32px; */
  /* color: white; */
  z-index: 1000;
  /* writing-mode: vertical-rl; */
  font-size: 20px;
  font-weight: 400;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 3px;
}

.flip-card-back-name-visa-logo {
  position: absolute;
  right: 78px;
  /* top: 105px; */
  bottom: 23px;
  /* left: 32px; */
  /* color: white; */
  z-index: 1000;
  /* writing-mode: vertical-rl; */
  font-size: 20px;
  font-weight: 400;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 3px;
}

.flip-card-back-name-cvv-set {
  position: absolute;
  right: 132px;
  top: 105px;
  /* bottom: 23px; */
  /* left: 32px; */
  /* color: white; */
  z-index: 1000;
  /* writing-mode: vertical-rl; */
  font-size: 20px;
  font-weight: 400;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 3px;
}

/* .flip-card-back-name{
    position: absolute;
    bottom: 0px; 
    left: 32px; 
    color: white; 
    z-index: 1000;
    
    font-size: 20px;
    font-weight: 400;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 3px;
  } */
/* 
  .flip-card-front-number{
    position: absolute;
    top: 40px; 
    right: 10px; 
    color: white; 
    z-index: 1000;
    font-size: 20px;
    font-weight: 400;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 3px;

  } */
.flip-card-front-number {
  position: absolute;
  left: 6%;
  top: 51%;
  color: white;
  z-index: 1000;
  font-size: 12px;
  font-weight: 400;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 5px;
  right:45px;
}

.flip-card-back-number {
  position: absolute;
  top: 40px;
  right: 10px;
  color: white;
  z-index: 1000;
  font-size: 20px;
  font-weight: 400;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 3px;
}

/* .flip-card-front-expiredate{
    position: absolute;
    top: 120px; 
    right: 10px; 
    color: white; 
    z-index: 1000;
    font-size: 20px;
    font-weight: 400;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 3px;
  } */

.flip-card-front-expiredate {
  position: absolute;
  right: 40px;
  bottom: 43px;
  color: white;
  z-index: 1000;
  font-size: 20px;
  font-weight: 400;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 3px;
}

.flip-card-back-expiredate {
  position: absolute;
  bottom: 0px;
  right: 25px;
  color: white;
  z-index: 1000;
  font-size: 20px;
  font-weight: 400;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 3px;
}

.input-1 input::placeholder {
  color: #fff;
}

select option {
  color: black;
}

.navCustomBtnLoginLogout {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-end;
  display: none;
}

@media screen and (max-width:990px) {
  .navCustomBtnLoginLogout {
    display: block;
  }
}

.btn10 {
  width: 'auto';
  /* border: 1px solid  blue; */
  border-radius: 6px;
  padding: 6px 9px 6px 9px;
  margin: 2px;
  color: white;
  text-align: center;
  background-color: blue;
}