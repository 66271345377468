@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
  margin: 0;
  padding: 0;
  font-style: poppins;
}

.card-container1 {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardOne {
  width: 250px;
  height: 350px !important;
  position: relative;
  transform-style: preserve-3d;
  animation: rotate1 9s linear infinite; 
}

@keyframes rotate1 {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.front1,
.back1 {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 5px;
}

.front1 {
  background-color: #ffcccc; /* Front card color */
  transform: rotate(15deg);
}

.back1 {
  background-color: #ccccff;
  transform: rotate(15deg) rotateY(180deg);
}


.front-content1,
.back-content1 {
  display: flex;
  height: 100%;
  position: relative;

}



.left1 {
  padding:  20px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid gray;
  background-color: #212022;
  color: #fff;

  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;

}

.right1 {
  padding:  20px 10px 20px 10px;

  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  background-color: black;
  color: #fff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

#brand1 {
  transform: rotate(90deg);
  font-size: 32px;
  font-weight: 600;
  margin-right: -15px;

}

.box1 {
  height: 40px;
  width: 40px;
  background-color: #d2d1d2;
  left: 53%;
  top: 30px;
  position: absolute;
  border-radius: 5px;
}

.bank1 {
  background-color: rgb(15, 103, 133);
  color: #fff;
  padding: 5px;
  font-size: 10px;
  width: fit-content;
  border-bottom: 2px solid rgb(201, 201, 38);
}

.name1 {
  display: flex;
  flex-direction: column;
  color: #d1cbc4;
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins;
}
.icon1{
  border-radius: 50%;
  background-color: white;
}