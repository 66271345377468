.div_back {
  height: 400px;
  width: 400px;
  border-radius: 50%;
  background-color: #5d8085;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 2s infinite alternate; /* Infinite animation */
}

.img_style_animiOne {
  height: 190px;
  width: 190px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.div_frunt {
  height: 290px;
  width: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #bcbcbc;
}
.img_style_animiOne {
  animation: pulse 2s infinite alternate;
}

@media screen and (max-width: 400px) {
  .div_back {
    height: 278px;
    width: 278px;
    border-radius: 50%;
    background-color: #434040;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pulse 2s infinite alternate;
  }
  .div_frunt {
    height: 230px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #bcbcbc;
  }
  .img_style_animiOne {
    height: 130px;
    width: 130px;
  }
}

.heading_8264 {
  position: absolute;
  letter-spacing: 0.2em;
  font-size: 0.5em;
  top: 2em;
  left: 18.6em;
}

.logos {
  position: absolute;
  top: 6.8em;
  left: 11.7em;
}

.chip {
  position: absolute;
  top: 2.3em;
  left: 1.5em;
}

.contactless {
  position: absolute;
  top: 3.5em;
  left: 12.4em;
}

.number {
  position: absolute;
  font-weight: bold;
  font-size: 0.6em;
  top: 8.3em;
  left: 1.6em;
}

.valid_thru {
  position: absolute;
  font-weight: bold;
  top: 635.8em;
  font-size: 0.01em;
  left: 140.3em;
}

.date_8264 {
  position: absolute;
  font-weight: bold;
  font-size: 0.5em;
  top: 13.6em;
  left: 3.2em;
}

.name {
  position: absolute;
  font-weight: bold;
  font-size: 0.5em;
  top: 16.1em;
  left: 2em;
}

.strip {
  position: absolute;
  background-color: black;
  width: 15em;
  height: 1.5em;
  top: 2.4em;
  background: repeating-linear-gradient(45deg, #303030, #303030 10px, #202020 10px, #202020 20px);
}

.mstrip {
  position: absolute;
  background-color: rgb(255, 255, 255);
  width: 8em;
  height: 0.8em;
  top: 5em;
  left: 0.8em;
  border-radius: 2.5px;
}

.sstrip {
  position: absolute;
  background-color: rgb(255, 255, 255);
  width: 4.1em;
  height: 0.8em;
  top: 5em;
  left: 10em;
  border-radius: 2.5px;
}

.code {
  font-weight: bold;
  text-align: center;
  margin: 0.2em;
  color: black;
}
.limit_card {
  border: 1px solid red;
}
.limitCardSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.limitCard {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* border: 1px solid black; */
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  height: 150px;
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.limitCard:hover {
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}

.card_banner {
  display: block;
  background-color: #544585;
  /* background-image: linear-gradient(160deg, #a8b6d4 0%, #80d0ccd8 100%); */
}

.hero {
  display: none;
}

@media screen and (min-width: 450px) {
  .card_banner {
    /* display: none; */
    align-items: center;
  }
  .offer_img {
    height: 300px;
  }
  .HomePageSlider {
    display: none;
  }
  .hero {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .limit_btn_css {
    margin-top: 70% !important;
  }
}

.log_out_btn {
  width: fit-content;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 4px;
}
